import * as Styled from "./TvlTable.style"
import { OverviewContext } from 'components/context/OverviewContext';
import React, {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function TvlTable() {

  const skeletonArray = [0,0,0,0,0,0,0,0,0,0,0,0,0,0]
  
  const { isloading, tvldata } = useContext(OverviewContext);  
  
  return (
    <Styled.TodoTemplateBlock>
      <p style={{ marginTop: "10px", marginLeft:"10px" }}>farmNote</p>
        <div className="tablecss" style={{ margin: "20px" }}>
      <Styled.Table>
        <thead>
          <Styled.TrHead>
            <Styled.Th>#</Styled.Th>
            <Styled.Tdp>Protocol - asset</Styled.Tdp>
            <Styled.Td width="200px">APR</Styled.Td>
          </Styled.TrHead>
        </thead>
        <tbody>
        {isloading ?
            skeletonArray.map((skelton,index)=>(
            <tr key={index} style={{ height: "40px", borderBottom: "0.06em solid #D4D4D4 " }}>
                 <Styled.Th className="head" style={{ width: "30px", textAlign: "left" }}>{index+1}</Styled.Th>
                 <Styled.Tdpd className="head" style={{ width: "30px", textAlign: "left", whiteSpace: "nowrap" }}>
                     <Styled.IconSkeleton style={{ padding: "1px",borderRadius: "15px",  verticalAlign: "bottom" }}/>
                     <Styled.ProductSkeleton marginTop="10px" style={{ padding: "7px", whiteSpace: "nowrap", paddingLeft:"10px"}}/>
                </Styled.Tdpd>
                 <Styled.Tdc className="head" style={{ width: "100px", fontSize:"14px", color:"#3f3f3f"}}><Styled.ProductSkeleton/></Styled.Tdc>
                 <Styled.Tdc className="head" style={{ width: "100px", fontSize:"14px", color:"#3f3f3f"}}><Styled.ProductSkeleton/></Styled.Tdc>
                 <Styled.Td className="content" style={{ width: "200px", textAlign: "right" }}><Styled.ProductSkeleton/></Styled.Td>
                <Styled.Td className="content" style={{ width: "50px", textAlign: "right" }}><Styled.ProductSkeleton/></Styled.Td>
                <Styled.Td className="content" style={{ width: "100px", textAlign: "right", color: "red" }}><Styled.ProductSkeleton/>%</Styled.Td>
                <Styled.Td className="content" style={{ width: "200px", textAlign: "right", color: "red" }}><Styled.ProductSkeleton/>%</Styled.Td>
                <Styled.Tdc className="content" style={{ width: "200px", textAlign: "right" }}><Styled.ProductSkeleton/>%</Styled.Tdc>
            </tr>
            )) :
            tvldata.data.map((tvld, index) => (
            <Tr key={index} style={{ height: "40px", borderBottom: "0.06em solid #D4D4D4" }}>
                <Styled.Th className="head" style={{ width: "50px", textAlign: "left" }}> {index+1}                 
                </Styled.Th>
                <Styled.Tdpd className="head" style={{ width: "30px", textAlign: "left", whiteSpace: "nowrap" }}>
                <a href={tvld.site} target="_blank" rel="noopener noreferrer">
                  {tvld.protocol} - {tvld.token}
                </a>
                </Styled.Tdpd>
                <Styled.Td className="head" style={{ width: "100px", fontSize:"14px", color:"#3f3f3f"}}>{tvld.apr.toFixed(2)}</Styled.Td>
            </Tr>
            ))
        }
        </tbody>
      </Styled.Table>
      </div>
  </Styled.TodoTemplateBlock>
  );
}

const Tr = styled.tr`
height : 40px;
line-height: 40px;
  &:hover {
    height : 40px;
    background-color: #E8E8E8;
    border-radius:10px;
    line-height: 40px;
  }
`


export default TvlTable;
  