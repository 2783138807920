import React from 'react';
// import RouteChangeTracker from './RouteChangeTracker';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import GlobalStyles from './assets/styles/GlobalStyles';
import ScrollToTop from 'util/ScrollTop';

import Overview from './pages/overview/Overview'
// import Nav from 'layout/nav'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Routes>
        {/* <Route path="/" element={<Nav />} /> */}
        {/* <Route path="/wallet" element={<Nav />} /> */}
        {/* <Route path="/klaytn" element={<Nav />} /> */}
        {/* <Route exact path="/wallet/:id" element={<Nav />} />
        <Route exact path="/Poolpage" element={<Nav />} />
        <Route exact path="/project/:id" element={<Nav />} />
        <Route exact path="/nftview" element={<Nav />} />
        <Route exact path="/analytics" element={<Nav />} />
        <Route exact path="/staking" element={<Nav />} />
        <Route exact path="/stables" element={<Nav />} />
        <Route exact path="/nftview/:id" element={<TopnavNftDetail />} />
        <Route exact path="/news" element={<Topnav />} /> */}
      </Routes>
      <Routes>
        <Route path="/" element={<Overview />} />
        {/* <Route path="/wallet" element={<Wallet />} /> */}
        {/* <Route path="/klaytn" element={<KlaytnOverview />} /> */}
        {/* <Route exact path="/wallet/:id" element={<Wallet />} />
        <Route exact path="/Poolpage" element={<Poolsearch />} />
        <Route exact path="/project/:id" element={<Detail />} />
        <Route exact path="/nftview" element={<Nftoverview />} />
        <Route exact path="/nftview/:id" element={<NftDetail />} />
        <Route exact path="/analytics" element={<Main />} />
        <Route exact path="/staking" element={<StakingPage />} />
        <Route exact path="/news" element={<Newspage />} />
        <Route exact path="/Strategy" element={<StrategyPage />} />
        <Route exact path="/stables" element={<StablePage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
