import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TvlTable from "./TvlTable"
import { OverviewContext } from "../../components/context/OverviewContext"

function Overview() {

    const [isloading, setIsloading] = useState(false)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const [tvldata, setTvldata] = useState({
        data: [{
            tvl: 0,
            apr: 0,
            protocol: "Navi",
            site: "https://klayswap.com/",
            token: "USDC"
        }]
      })   

      
      
    useEffect(() => {
      fetchAndMapUsdcData()
        // loadEvent()
        // loadchart()
    }, [])

    async function fetchAndMapUsdcData() {
      try {
        let tempArray = [];
        
        // Fetch the USDC data from the API
        const response = await axios.get('https://sdk.api.scallop.io/api/radar/usdc');
        const data = response.data;
    
        // Map the response to the desired structure
        const mappedData = data.map(item => {
          let siteUrl = "";
          let aprValue = Number(item.supplyRewardAprPercentage) + Number(item.supplyAprPercentage);
    
          // Check if the protocol is "navi" and add 2 to the apr value
          if (item.protocolName.toLowerCase() === "navi") {
            aprValue += 2.6;
          }
    
          switch (item.protocolName.toLowerCase()) {
            case "scallop":
              siteUrl = "https://app.scallop.io/";
              break;
            case "navi":
              siteUrl = "https://app.naviprotocol.io/";
              break;
            case "suilend":
              siteUrl = "https://suilend.fi/dashboard";
              break;
            default:
              siteUrl = "";
          }
    
          return {
            tvl: 0, // Assuming TVL is 0 as per your structure
            apr: aprValue,
            protocol: item.protocolName,
            site: siteUrl,
            token: "USDC"
          };
        });
    
        // Fetch the USDT data from the API
        const responseUSDT = await axios.get('https://sdk.api.scallop.io/api/radar/usdt');
        const dataUSDT = responseUSDT.data;
    
        // Map the response to the desired structure
        const mappedDataUSDT = dataUSDT.map(item => {
          let siteUrl = "";
          let aprValue = Number(item.supplyRewardAprPercentage) + Number(item.supplyAprPercentage);
    
          // Check if the protocol is "navi" and add 2 to the apr value
          if (item.protocolName.toLowerCase() === "navi") {
            aprValue += 2.6;
          }
    
          switch (item.protocolName.toLowerCase()) {
            case "scallop":
              siteUrl = "https://app.scallop.io/";
              break;
            case "navi":
              siteUrl = "https://app.naviprotocol.io/";
              break;
            case "suilend":
              siteUrl = "https://suilend.fi/dashboard";
              break;
            default:
              siteUrl = "";
          }
    
          return {
            tvl: 0, // Assuming TVL is 0 as per your structure
            apr: aprValue,
            protocol: item.protocolName,
            site: siteUrl,
            token: "USDT"
          };
        });
    
        const combinedData = [...mappedData, ...mappedDataUSDT];
        combinedData.sort((a, b) => b.apr - a.apr);
        setTvldata({data: combinedData});
    
      } catch (error) {
        console.error('Error fetching or mapping data:', error);
        return []; // Return an empty array in case of error
      }
    }

  return (
    <>
        <OverviewContext.Provider value={{tvldata}}>
          <TvlTable value={{tvldata}}/>
        </OverviewContext.Provider>
    </>
  );
}

export default Overview;
